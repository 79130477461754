<template>
  <div class="about">
    <h1>About Page</h1>
    <p>Learn more about us on this page.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage'
};
</script>

<style scoped>
.about {
  padding: 20px;
}
</style>
